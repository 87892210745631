import React from "react";
import { withRouter } from "react-router-dom";

@withRouter
class CertficationView extends React.Component {
  constructor(props) {
    super(props);
    const { location, history } = this.props;
    const params = Utils.queryFormat(location.search);
    if (params.token) {
      Utils.setCache('token', params.token, 'session');
      Service.user.getUserIdByToken().then(res => {
        if(typeof res.data !== 'string'){
          return Promise.reject(res)
        }
        Utils.setCache('userId', res.data, 'session');
        BaseStore.user.setLoginState(true);
        history.replace("/");
      }).catch(e => {
        sessionStorage.removeItem('token')
        history.replace("/");
        console.error(e)
      });
    } else {
      history.replace("/");
    }
  }
  render() {
    return null;
  }
}

export default CertficationView;
